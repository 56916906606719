import {
  AcpEnvironment,
  StatusBarForegroundColor
} from 'apps/acp/packages/acp-config';
import { ACPVariant } from 'apps/acp/variants/acp-variant';

export const mapVariantToAcpEnv = (variant: ACPVariant) => {
  // By ignoring this import, we break ourselves of transitive dependency
  // of *everything* for acp-mfes, so we do not have to rebuild this variant
  // specific module on every change. Its a risk we are willing to take
  // since the entire app will not bootstrap if this is broken, and will
  // be ovious in the simplist of tests. We will still have to re-create
  // templates for each variant, but that must occur no matter what to inline
  // the import map anway and at least does not cause a typescript compile
  // @ts-ignore: see above comment

  const isCloudMobile =
    navigator.userAgent.toLowerCase().indexOf('cordova') === -1;

  const stampOrDefault = (val: string, def: string) =>
    val.startsWith('$' + '(') ? def : val;

  const metaVersion =
    document.head
      .querySelector('meta[name=ns\\:rr_version]')
      ?.getAttribute('content') || '';
  const acpMode =
    document.head
      .querySelector('meta[name=ns\\:acp_mode]')
      ?.getAttribute('content') || '';
  const version = stampOrDefault(metaVersion, '');
  const isDist = !!version;

  // If we have a stamped variable indicating dist mode, then we use the interpolated mode, else fallback to dev
  const mode = (((isDist && acpMode) ||
    'dev') as any) as AcpEnvironment['config']['mode'];

  const mappedInfo: AcpEnvironment = {
    config: {
      // Constants for ACP
      appName: 'Account Center',
      apiResourceTimeout: 60000,
      googleMapsVersion: '3.24',
      platform: 'web',
      platformType: 'web',

      // Variables from build process
      mode: mode,
      isDist: isDist,
      version: version,
      appVersion: version,

      // AcpVariant['*'] Top Level configs
      appTitle: variant.productTitle,
      variant: variant.id,
      brand: variant.legacyAcpBrandName,
      programType: variant.productType,
      theme: variant.legacyAcpTheme,
      legosTheme: variant.legacyLegosTheme,
      themeConfiguration: variant.themeConfiguration,
      logoOverrides: variant.logoOverrides,

      statusBarBackgroundColor: variant.status_bar_background_color,
      statusBarForegroundColor: variant.status_bar_foreground_color as StatusBarForegroundColor,

      // AcpVariant['configuration']['apiTokens']
      gtmContainerId: isCloudMobile
        ? variant.configuration.apiKeys['google-tag-manager-container-id']
        : variant.configuration.apiKeys[
            'google-tag-manager-container-id-mobile'
          ],
      appsflyerDevKey: variant.configuration.apiKeys.appsFlyerDevKey,
      appsFlyerBannerKey: variant.configuration.apiKeys.appsFlyerBannerKey,
      paypalFraudNetPayerId:
        variant.configuration.apiKeys[
          mode === 'production'
            ? 'paypalFraudNetPayerIdProd'
            : 'paypalFraudNetPayerIdTest'
        ],

      // AcpVariant['configuration']['featureToggles']
      showEmailInformationButton:
        variant.configuration.featureToggles.showEmailInformationButton,
      showWUTransfers: variant.configuration.featureToggles.showWUTransfers,
      showFeePlanPage: variant.configuration.featureToggles.showFeePlanPage,
      showAcquisitionLink:
        variant.configuration.featureToggles.showAcquisitionLink,
      showRegisterLink: variant.configuration.featureToggles.showRegisterLink,
      showMarketingSiteLink:
        variant.configuration.featureToggles.showMarketingSiteLink,
      showFreeAtmFinderLink:
        variant.configuration.featureToggles.showFreeAtmFinderLink,
      mobilePBREnabled: variant.configuration.featureToggles.mobilePBREnabled,
      showAccountDisplayNickname:
        variant.configuration.featureToggles.showAccountDisplayNickname,
      preOnboardingEnabled:
        variant.configuration.featureToggles.preOnboardingEnabled,
      activationShowScanCardRegistration:
        variant.configuration.featureToggles.showScanCardRegistration,
      activationSetExternalId:
        variant.configuration.featureToggles.activationSetExternalId,
      showHowToEarnRewardPointLink:
        variant.configuration.featureToggles.showHowToEarnRewardPointLink,
      embeddedPayNearMe: variant.configuration.featureToggles.embeddedPayNearMe,
      appsflyerEnabled: variant.configuration.featureToggles.appsFlyerEnabled,
      showDisplayNameForSubAccount:
        variant.configuration.featureToggles.showDisplayNameForSubAccount,
      combinedTransactionsMasterAndSub:
        variant.configuration.featureToggles.combinedTransactionsMasterAndSub,
      hasNotificationCenter:
        variant.configuration.featureToggles.hasNotificationCenter,
      showReloadLocationLink:
        variant.configuration.featureToggles.showReloadLocationLink,
      // AcpVariant['configuration']['misc']
      distributor: variant.configuration.misc.distributor,
      marketingSiteUrl: variant.configuration.misc.marketingSiteUrl,
      marketingSiteLinkText: variant.configuration.misc.marketingSiteLinkText,
      atmFinderLinkText: variant.configuration.misc.marketingSiteLinkText,
      addMoneyLinkText: variant.configuration.misc.marketingSiteLinkText,
      storeNameOverride: variant.configuration.misc.storeNameOverride,
      customizedLoginPage: variant.configuration.misc.customizedLoginPage,
      atmNetwork: variant.configuration.misc.atmNetwork,
      layoutComponentOverride:
        variant.configuration.misc.layoutComponentOverride,
      layoutAppBarInverted: variant.configuration.misc.layoutAppBarInverted,
      contactRecipient: variant.configuration.misc.contactRecipient,
      iOSAppId: variant.configuration.misc.iOSAppId,
      payNearMeConfig: {
        buttonTextColor: variant.configuration.misc.payNearMeButtonTextColor,
        actionBarBackgroundColor:
          variant.configuration.misc.payNearMeActionBarBackgroundColor,
        titleTextColor: variant.configuration.misc.payNearMeTitleTextColor,
        colorAccent: variant.configuration.misc.payNearMeColorAccent
      },
      externalLoyaltyConfig: {
        name: variant.configuration.misc.externalLoyaltyProgramName,
        icon: variant.configuration.misc.externalLoyaltyProgramIcon
      },
      cashRewardsConfig: {
        name: variant.configuration.misc.cashRewardsProgramName,
        icon: variant.configuration.misc.cashRewardsProgramIcon
      },
      isCashRewardProgram: variant.configuration.misc.isCashRewardProgram,
      adaChatbotHandle: variant.configuration.misc.adaChatbotHandle,
      // AcpTenant['configuration']['content']
      openAccountButtonContent:
        variant.configuration.content.openAccountButtonContent,
      signUpContent: variant.configuration.content.signUpContent,
      signUpDisclaimerContent:
        variant.configuration.content.signUpDisclaimerContent,
      openAccountFooterContent:
        variant.configuration.content.openAccountFooterContent,
      productNameAdjective: variant.configuration.misc.productNameAdjective,
      copyrightStatement: variant.configuration.content.copyrightStatement,
      sendReceiveMoneyText: variant.configuration.content.sendReceiveMoneyText,
      sendReceiveMoneyIcon: variant.configuration.content.sendReceiveMoneyIcon,
      accountTypeText: variant.configuration.content.accountTypeText,
      fundAccountText: variant.configuration.content.fundAccountText,
      benefitsCenterTitle: variant.configuration.content.benefitsCenterTitle,
      esignProductNameContent:
        variant.configuration.content.esignProductNameContent,
      mastercardStatement: variant.configuration.content.mastercardStatement,

      // AcpVariant['configuration']['disclaimers']
      optionalSavingsDisclaimer:
        variant.configuration.disclaimers.optionalSavingsDisclaimer,
      loyaltyProgramDetailDisclaimer:
        variant.configuration.disclaimers.loyaltyProgramDetailDisclaimer,
      fasterFundingDisclaimer:
        variant.configuration.disclaimers.fasterFundingDisclaimer,
      paybackRewardsDisclaimer:
        variant.configuration.disclaimers.paybackRewardsDisclaimer,
      cardDisclaimer: variant.configuration.disclaimers.cardDisclaimer,
      rideShareDisclaimer:
        variant.configuration.disclaimers.rideShareDisclaimer,
      cellPhoneProtectionDisclaimer:
        variant.configuration.disclaimers.cellPhoneProtectionDisclaimer,
      idProtectionDisclaimer:
        variant.configuration.disclaimers.idProtectionDisclaimer,
      atmDisclaimer: variant.configuration.disclaimers.atmDisclaimer,
      directDepositDisclaimer:
        variant.configuration.disclaimers.directDepositDisclaimer,
      participationFeeDisclaimer:
        variant.configuration.disclaimers.participationFeeDisclaimer,
      fivePercentDisclaimer:
        variant.configuration.disclaimers.fivePercentDisclaimer,
      inAppBrowserAcquisitionToolbarBackgroundColor:
        variant.configuration.misc
          .inAppBrowserAcquisitionToolbarBackgroundColor,
      inAppBrowserAcquisitionDisableToolbarText:
        variant.configuration.misc.inAppBrowserAcquisitionDisableToolbarText,
      inAppBrowserAcquisitionDisableToolbarTranslucent:
        variant.configuration.misc
          .inAppBrowserAcquisitionDisableToolbarTranslucent,
      overdraftDisclaimer:
        variant.configuration.disclaimers.overdraftDisclaimer,
      importantPatriotActDisclosure:
        variant.configuration.disclaimers.importantPatriotActDisclosure,

      // AcpVariant['configuration']['sections']
      sections: variant.configuration.sections,
      benefits: variant.benefits
    },
    branding: variant.contactInfo,
    colors: variant.colors
  };

  return mappedInfo;
};
